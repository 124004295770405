import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Container } from '@mui/material';

import Loading from './components/Loading';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './views/Home';
import Profile from './views/Profile';
import ExternalApi from './views/ExternalApi';
import { useAuth0 } from '@auth0/auth0-react';
import history from './utils/history';

// styles
import './App.css';

// fontawesome
import initFontAwesome from './utils/initFontAwesome';
initFontAwesome();

const STALE_TIME = process.env.QUERY_STALE_TIME || 2.5; // Time in **MINUTES** to be used when setting the staleTime configuration.

const queryClient = new QueryClient({
	defaultOptions: {
		mutations: { onError: async (error) => console.error(`Mutation error [${error}]`) },
		queries: {
			onError: async (error) => console.error(`Query error [${error}]`),
			staleTime: 1000 * 60 * STALE_TIME,
		},
	},
});

const App = () => {
	const { isLoading, error } = useAuth0();

	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	if (isLoading) {
		return <Loading />;
	}

	return (
		<QueryClientProvider client={queryClient}>
			<Router history={history}>
				<div id='app' className='d-flex flex-column h-100'>
					<NavBar />
					<Container sx={{ my: 3 }}>
						<Switch>
							<Route path='/' exact component={Home} />
							<Route path='/profile' component={Profile} />
							<Route path='/external-api' component={ExternalApi} />
						</Switch>
					</Container>
					<Footer />
				</div>
			</Router>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
};

export default App;
