import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Container, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Add as AddIcon,
	DeleteOutline as TrashIcon,
	Fingerprint as FingerprintIcon,
	LockOutlined as LockIcon,
	MailOutline as EmailIcon,
	PhoneAndroidOutlined as PhoneIcon,
	Usb as UsbIcon,
} from '@mui/icons-material';
import { useIsMutating } from 'react-query';

import useEnrollMfaMutation from '../hooks/useEnrollMfaMutation';
import useRemoveAuthenticatorMutation from '../hooks/useRemoveAuthenticatorsMutation';
import { useAuthenticatorsQuery } from '../hooks/useAuthenticatorsQuery';

import AuthenticatorModal from './AuthenticatorModal';

const authenticatorTypeMap = {
	sms: 'SMS',
	'push-notification': 'Push',
	otp: 'One-Time Passcode',
	email: 'Email',
	duo: 'Duo',
	'webauthn-roaming': 'WebAuthn with FIDO Security Keys',
	'webauthn-platform': 'Device Biometrics',
	'recovery-code': 'Recovery Code',
};

const RemoveButton = (props) => (
	<LoadingButton startIcon={<TrashIcon fontSize='sm' />} color='error' loadingPosition='start' {...props}>
		{props?.label ?? 'Remove'}
	</LoadingButton>
);

const Authenticators = (props) => {
	const { isLoading: isLoadingAuthenticators, data: authenticators } = useAuthenticatorsQuery();
	// const isLoadingRemove = useIsMutating('remove-authenticator') > 0;

	// const { isLoading, data: authenticators } = useAuthenticatorsQuery();
	// const isLoadingAuthenticators = true;

	const { isLoading: isLoadingAdd, mutate: enrollAuthenticator } = useEnrollMfaMutation();
	const { isLoading: isLoadingRemove, mutate: removeAuthenticator } = useRemoveAuthenticatorMutation();

	const initModalState = {
		open: false,
		authenticator: 'email',
	};

	// eslint-disable-next-line no-unused-vars
	const [modalState, setModalState] = React.useState({ ...initModalState });
	const [ticketUrl, setTicketUrl] = React.useState();

	const resetModalState = () => setModalState(() => initModalState);

	const onSubmit = (value) => {
		console.log(value);
		resetModalState();
	};

	const toggleModal = (options) =>
		setModalState(() => ({
			...initModalState,
			onSubmit,
			onClose: resetModalState,
			...options,
		}));

	return (
		<Container sx={{ py: 3 }}>
			<AuthenticatorModal {...modalState} />
			<h3>Authenticators</h3>
			<List>
				{isLoadingAuthenticators && (
					<ListItem key='loader' alignItems='center' sx={{ justifyContent: 'center' }}>
						<CircularProgress size={56} />
					</ListItem>
				)}
				{!isLoadingAuthenticators && (
					<>
						{authenticators.map((authenticator, index, array) => {
							const { authenticator_type, oob_channel, name, id, active } = authenticator;

							const key = id || `authenticator-${index}`;

							const authenticatorType = authenticatorTypeMap[oob_channel ?? authenticator_type];
							const type = authenticatorType;
							const label = active && name ? name : type;

							let icon;

							switch (oob_channel ?? authenticator_type) {
								case 'sms':
									icon = <PhoneIcon />;
									break;
								case 'email':
									icon = <EmailIcon />;
									break;
								case 'webauthn-platform':
									icon = <FingerprintIcon />;
									break;
								case 'webauthn-roaming':
									icon = <UsbIcon />;
									break;
								default:
									icon = <LockIcon />;
									break;
							}

							return (
								<ListItem
									key={key}
									divider
									secondaryAction={<RemoveButton onClick={() => removeAuthenticator(id)} loading={isLoadingRemove} />}
								>
									<ListItemIcon>{icon}</ListItemIcon>
									<ListItemText
										primary={label}
										secondary={active ? `Enrolled using ${authenticatorType}` : 'Not enrolled'}
									/>
								</ListItem>
							);
						})}
						<ListItem key='add-buttons' sx={{ pt: 3 }}>
							<Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
								{authenticators?.length === 0 && (
									<LoadingButton
										startIcon={<AddIcon fontSize='sm' />}
										variant='outlined'
										onClick={() => enrollAuthenticator(setTicketUrl)}
										loadingPosition='start'
										loading={isLoadingAdd}
										{...props}
									>
										Add
									</LoadingButton>
								)}
								{authenticators?.length > 0 && (
									<>
										<Grid item>
											<Button
												startIcon={<EmailIcon fontSize='sm' />}
												variant='outlined'
												onClick={() => toggleModal({ open: true })}
												{...props}
											>
												Add Email
											</Button>
										</Grid>
										<Grid item>
											<Button
												startIcon={<PhoneIcon fontSize='sm' />}
												variant='outlined'
												onClick={() => toggleModal({ open: true, authenticator: 'sms' })}
												{...props}
											>
												Add SMS
											</Button>
										</Grid>
									</>
								)}
							</Grid>
						</ListItem>
					</>
				)}
			</List>
		</Container>
	);
};

Authenticators.defaultProps = {
	authenticators: [],
};

Authenticators.propTypes = {
	authenticators: PropTypes.arrayOf(PropTypes.object),
};

export default Authenticators;
