import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';

import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/monokai-sublime.css';

hljs.registerLanguage('json', json);

const CodeSnippet = (props) => {
	const { children } = props;
	const codeNode = React.createRef();

	React.useEffect(() => {
		codeNode && codeNode.current && hljs.highlightElement(codeNode.current);
	}, [codeNode]);

	return (
		<Container disableGutters sx={{ height: '100%' }}>
			<pre className='rounded' style={{ marginBottom: '0px' }}>
				<code ref={codeNode}>{children}</code>
			</pre>
		</Container>
	);
};

CodeSnippet.propTypes = {
	children: PropTypes.node.isRequired,
};

export default CodeSnippet;
