import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

import { getConfig } from '../config';

const authenticatorsQueryFn = async (sdk) => {
	const { domain } = getConfig();

	const accessToken = await sdk.getAccessTokenSilently();

	const url = `https://${domain}/mfa/authenticators`;

	const response = await fetch(url, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});

	if (!response.ok) {
		const body = await response.clone().json();

		throw new Error(`${response.status} | ${JSON.stringify(body, null, 2)}`);
	}

	const data = await response.json();

	return data.filter(({ active }) => active === true);
};

export const useAuthenticatorsQuery = () => {
	const auth0 = useAuth0();

	return useQuery(['authenticators'], () => authenticatorsQueryFn(auth0));
};

export const authenticatorsQuery = authenticatorsQueryFn;
