import { InputAdornment, TextField } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const EmailForm = ({ onChange, value }) => (
	<TextField
		required
		id='email-input'
		label='Email'
		type='email'
		autoFocus
		fullWidth
		autoComplete='email'
		autoCapitalize='none'
		variant='standard'
		margin='normal'
		onChange={onChange}
		value={value}
		InputProps={{
			startAdornment: (
				<InputAdornment position='start'>
					<MailOutlineIcon />
				</InputAdornment>
			),
		}}
	/>
);

export default EmailForm;
