import React from 'react';
import {
	Avatar,
	Container,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import Authenticators from '../components/Authenticators';
import CodeSnippet from '../components/CodeSnippet';
import Loading from '../components/Loading';

export const ProfileComponent = () => {
	const { user } = useAuth0();

	return (
		<Container>
			<Stack spacing={3}>
				<List>
					<ListItem alignItems='flex-start' disableGutters>
						<ListItemAvatar sx={{ pr: 3, m: 0 }}>
							<Avatar src={user.picture} alt='avatar' sx={{ width: 120, height: 120 }} />
						</ListItemAvatar>
						<ListItemText
							primary={
								<Typography variant='h4' sx={{ fontWeight: 'bold' }}>
									{user.name}
								</Typography>
							}
							secondary={
								<Typography variant='overline' sx={{ fontSize: 16 }}>
									{user.email}
								</Typography>
							}
						/>
					</ListItem>
				</List>
				<Paper elevation={3}>
					<CodeSnippet>{JSON.stringify(user, null, 2)}</CodeSnippet>
				</Paper>
				<Paper elevation={3}>
					<Authenticators />
				</Paper>
			</Stack>
		</Container>
	);
};

export default withAuthenticationRequired(ProfileComponent, {
	onRedirecting: () => <Loading />,
});
