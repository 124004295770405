import { useMutation, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

import { getConfig } from '../config';

const removeAuthenticatorFn = async (sdk, id) => {
	if (!id) {
		throw new Error('An authenticator ID must be provided!');
	}

	const { domain } = getConfig();

	const accessToken = await sdk.getAccessTokenSilently();

	const url = `https://${domain}/mfa/authenticators/${id}`;

	const response = await fetch(url, {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});

	if (response.status !== 204) {
		let body;

		try {
			body = await response.clone().json();
		} catch {}

		throw new Error(`${response.status} | ${body ? JSON.stringify(body, null, 2) : undefined}`);
	}

	return true;
};

const useRemoveAuthenticatorMutation = () => {
	const auth0 = useAuth0();
	const queryClient = useQueryClient();

	return useMutation(['remove-authenticator'], (id) => removeAuthenticatorFn(auth0, id), {
		onSuccess: async () => queryClient.invalidateQueries('authenticators'),
	});
};

export default useRemoveAuthenticatorMutation;
