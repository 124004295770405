const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const SCOPES = process.env.REACT_APP_SCOPES || 'openid profile email';
const AUDIENCE = process.env.REACT_APP_AUDIENCE || `https://${AUTH0_DOMAIN}/mfa/`;

export function getConfig() {
	// Configure the audience here. By default, it will take whatever is in the config
	// (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
	// is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
	// don't have an API).
	// If this resolves to `null`, the API page changes to show some helpful info about what to do
	// with the audience.

	const cacheLocation = process.env !== 'production' ? 'localstorage' : 'memory';

	return {
		cacheLocation,
		domain: AUTH0_DOMAIN,
		clientId: CLIENT_ID,
		scope: SCOPES,
		audience: AUDIENCE,
		useRefreshTOkens: true,
	};
}
