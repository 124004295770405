import React from 'react';
import { InputAdornment, ListItemIcon, ListItemText, MenuItem, TextField } from '@mui/material';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { styled } from '@mui/material/styles';
import { IMaskInput } from 'react-imask';

import { ReactComponent as AU } from '../../assets/flags/au.svg';
import { ReactComponent as CA } from '../../assets/flags/ca.svg';
import { ReactComponent as CH } from '../../assets/flags/ch.svg';
import { ReactComponent as GB } from '../../assets/flags/gb.svg';
import { ReactComponent as MX } from '../../assets/flags/mx.svg';
import { ReactComponent as US } from '../../assets/flags/us.svg';

const PhoneMaskInput = React.forwardRef((props, ref) => {
	const { onChange, ...other } = props;

	return (
		<IMaskInput
			{...other}
			mask='(#00) 000-0000'
			unmask
			definitions={{ '#': /[1-9]/ }}
			inputRef={ref}
			onAccept={(value) => onChange({ target: { value } })}
			overwrite
		/>
	);
});

const PhoneForm = ({ onChange }) => {
	const CountrySelector = styled(TextField)(() => ({
		'& .MuiInput-input': {
			display: 'flex',
			alignItems: 'center',
		},
		'& .MuiListItemIcon-root': {
			minWidth: 'unset',
			height: '24px',
			padding: '0px 8px 0px 4px',
		},
	}));

	const countries = [
		{
			name: 'Australia',
			value: '+61',
			code: 'AUS',
			flag: <AU />,
		},
		{
			name: 'Canada',
			value: '+1',
			code: 'CA',
			flag: <CA />,
		},
		{
			name: 'Mexico',
			value: '+52',
			code: 'MX',
			flag: <MX />,
		},
		{
			name: 'Switzerland',
			value: '+41',
			code: 'CH',
			flag: <CH />,
		},
		{
			name: 'United Kingdom',
			value: '+44',
			code: 'UK',
			flag: <GB />,
		},
		{
			name: 'United States',
			value: '+1',
			code: 'US',
			flag: <US />,
		},
	];
	const [country, setCountry] = React.useState('US');
	const [phoneNumber, setPhoneNumber] = React.useState('');

	const onChangeNumber = (event) => {
		const areaCode = countries.find(({ code }) => code === country)?.value;

		const newValue = areaCode + event.target.value;

		setPhoneNumber(event.target.value);

		onChange({ target: { value: newValue } });
	};

	const onSelect = (event) => setCountry(event.target.value);

	return (
		<>
			<CountrySelector
				id='country-selector'
				select
				label=''
				fullWidth
				required
				value={country}
				onChange={onSelect}
				variant='standard'
				margin='normal'
			>
				{countries.map(({ code, value, name, flag }) => (
					<MenuItem key={code} value={code}>
						<ListItemIcon>{flag}</ListItemIcon>
						<ListItemText>{`${name}, ${code}, ${value}`}</ListItemText>
					</MenuItem>
				))}
			</CountrySelector>
			<TextField
				required
				id='phone-input'
				label=''
				placeholder='Enter your phone number'
				type='tel'
				autoFocus
				fullWidth
				autoComplete='mobile tel'
				inputMode='numeric'
				variant='standard'
				margin='normal'
				onChange={onChangeNumber}
				value={phoneNumber}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<PhoneAndroidOutlinedIcon />
						</InputAdornment>
					),
					inputComponent: PhoneMaskInput,
				}}
			/>
		</>
	);
};

export default PhoneForm;
