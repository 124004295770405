import { useMutation, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

const enrollAuthenticatorFn = async (sdk) => {
	const {
		getAccessTokenSilently,
		user: { sub },
	} = sdk;

	const accessToken = await getAccessTokenSilently();

	const url = `${window.location.origin}/api/authenticators/${sub}`;

	const response = await fetch(url, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});

	if (response.status !== 200) {
		let body;

		try {
			body = await response.clone().json();
		} catch {}

		throw new Error(`${response.status} | ${body ? JSON.stringify(body, null, 2) : undefined}`);
	}

	return await response.json();
};

const loadPopup = (url) => {
	const popupWidth = 400;
	const popupHeight = 600;
	const popupLeft = (window.screen.width - popupWidth) / 2;
	const popupTop = (window.screen.height - popupHeight) / 4;

	return window.open(
		url,
		null,
		`popup=yes,screenY=${popupTop},screenX=${popupLeft},height=${popupHeight},width=${popupWidth},menubar=no,toolbar=no`
	);
};

const useEnrollMfaMutation = () => {
	const auth0 = useAuth0();
	const queryClient = useQueryClient();

	return useMutation(['enroll-mfa'], async () => await enrollAuthenticatorFn(auth0), {
		onSuccess: async ({ ticket_url }) => {
			if (ticket_url) {
				const popup = loadPopup(ticket_url);

				const poller = setInterval(function () {
					if (popup.closed) {
						clearInterval(poller);
						return queryClient.invalidateQueries('authenticators');
					}
				}, 1000);
			}
		},
	});
};

export default useEnrollMfaMutation;
