import React from 'react';
import { Container } from '@mui/material';

const Footer = () => (
	<Container component='footer' maxWidth={false} sx={{ marginTop: 8 }}>
		<div className='logo' />
		<p>
			Sample project provided by <a href='https://auth0.com'>Auth0</a>
		</p>
	</Container>
);

export default Footer;
