import React from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import EmailForm from './AuthenticatorModal/EmailForm';
import PhoneForm from './AuthenticatorModal/PhoneForm';

const AuthenticatorDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		overflowY: 'unset',
		background: 'white',
		padding: '0px 16px 16px',
	},
	'& .MuiDialog-root': {
		overflowY: 'unset',
	},
	'& .MuiDialogContent-root': {
		overflowY: 'unset',
	},
	'& .MuiDialogContentText-root': {
		paddingBottom: 24,
		overflowY: 'unset',
	},
	'& .MuiDialogTitle-root': {
		overflowY: 'unset',
	},
}));

const AuthenticatorModal = (props) => {
	const { authenticator: authenticatorType, onClose, onSubmit } = props;

	const [value, setValue] = React.useState('');

	const onChange = (event) => setValue(event.target.value);

	const resetState = () => setValue(() => '');

	const handleClose = () => {
		resetState();
		onClose();
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		resetState();
		onSubmit(value);
	};

	const isEmail = authenticatorType === 'email';
	const dialogText = `Enter your ${isEmail ? 'email address' : 'phone number'} where we can send you a 6-digit code.`;

	return (
		<AuthenticatorDialog centered='true' maxWidth='xs' {...props}>
			<DialogTitle>
				<IconButton
					edge='end'
					size='small'
					onClick={handleClose}
					sx={{
						color: 'white',
						position: 'absolute',
						borderRadius: 25,
						background: 'rgba(0, 0, 0, 0.53)',
						right: -15,
						top: -15,
						'z-index': '1400',
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Box component='form' id='authenticator-enroll' noValidate onSubmit={handleSubmit}>
					<DialogContentText>{dialogText}</DialogContentText>
					{isEmail && <EmailForm value={value} onChange={onChange} />}
					{!isEmail && <PhoneForm onChange={onChange} />}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button type='submit' form='authenticator-enroll'>
					Submit
				</Button>
			</DialogActions>
		</AuthenticatorDialog>
	);
};

export default AuthenticatorModal;
